import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  constructor(private _http: HttpClient) {}

  orderSearch(input: { page: number; perpage: number; sort: string; search: string }) {
    return this._http.post('/services/business/orders/search', input)
  }

  getslipCredit() {
    return this._http.get('/services/business/credit/slip_credit').toPromise()
  }

  searchCredit(model: any) {
    return this._http.post('/services/business/credit/search_credit_slip', model).toPromise()
  }

  addCredit(model: any) {
    return this._http.post('/services/business/credit/add_credit', model).toPromise()
  }
  checkStatus(model: any) {
    return this._http.post('/services/business/credit/check_status', model).toPromise()
  }
}
