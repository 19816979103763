import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root',
})
export class KetListLogFileImportService {
  constructor(private _http: HttpClient) {}

  searchLogImport(model: any) {
    return this._http.post('/services/ketshopweb-products/imports/searchLogImport', model).toPromise()
  }
}
