import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

export namespace IKetshopwebOrdersService {
  export interface SimpleSearch {
    channel_name: string
    startdate: string
    enddate: string
    status: number | string
    ordercode: string
    email: string
    trackcode: string
    search: string
    paymenttype: string | number
    created_by: string
    totals: number | string
    page: number
    perpage: number
    confirm_payment: number | string
    channels: string[]
    is_tax_no: boolean
    is_note: boolean
    product_id?: number
    order_ids?: number[]
    is_full_column?: boolean
    is_full_details?: boolean
  }
}

@Injectable({
  providedIn: 'root',
})
export class KetshopwebOrdersService {
  private http = inject(HttpClient)

  constructor() {}

  simpleSearch(model: IKetshopwebOrdersService.SimpleSearch) {
    return this.http.post(`/services/ketshopweb-orders/search/simpleSearch`, model) as Observable<any>
  }

  listChannel() {
    return this.http.get(`/services/ketshopweb-orders/channels/list_channel`).toPromise() as Promise<{
      color: string
      name: string
      img: string
      imgapp: string
      group: 'marketplace' | 'website'
    }>
  }

  orderInfoForAccount(ref: string) {
    return this.http.get(`/services/ketshopweb-orders/orders/order-info-for-account/` + ref).toPromise() as Promise<{
      ordercode: string
      bill_number: string
      created_at: string
      updated_at: string
      tax_info: {
        id: number
        ordercode: string
        user_id: number
        tax_no: string
        name: string
        address: string
        subdistrict: string
        district: string
        province: string
        zipcode: string
        type: string
        email: string
        tel: string
        created_at: string
        updated_at: string
      }
      form_header: string
      form_des: string
      submit: boolean
    }>
  }
  // create_tax_no
  createTaxNo(input: any) {
    return this.http.post(`/services/ketshopweb-orders/orders/create_tax_no`, input).toPromise() as Promise<any>
  }
}
