import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private _http: HttpClient) {}

  getLogs(path) {
    const date = +new Date()
    return $.ajax({
      url: `${path}?d=${date}`,
      type: 'GET',
    })
    // return this._http.get(`${path}`).toPromise();
  }

  getAllLogs() {
    return this._http.get('/report/goaccessLog').toPromise()
  }

  warehouseSummary(data: any) {
    return this._http.post('/report/warehouseSummaryStatus', data).toPromise()
  }
  productDeadStock(data: any) {
    return this._http.post('/report/productDeadStock', data).toPromise()
  }
  productLowActivity(data: any) {
    return this._http.post('/report/productLowActivity', data).toPromise()
  }
  productHighActivity(data: any) {
    return this._http.post('/report/productHighActivity', data).toPromise()
  }
  orderReportSold(data: any) {
    return this._http.post('/report/orderReportSold', data).toPromise()
  }
  orderReportSummary(data: any) {
    return this._http.post('/report/orderReportSummary', data).toPromise()
  }
  topBestSeller(data: any) {
    return this._http.post('/report/topBestSeller', data).toPromise()
  }
  topBestSellerByCate(data: any) {
    return this._http.post('/report/topBestSellerByCate', data).toPromise()
  }
  orderChannel(data: any) {
    return this._http.post('/report/orderChannel', data).toPromise()
  }
  orderChannelValue(data: any) {
    return this._http.post('/report/orderChannelValue', data).toPromise()
  }
  orderChannelByStatus(data: any) {
    return this._http.post('/report/orderChannelByStatus', data).toPromise()
  }
  orderReportBySale(data: any) {
    return this._http.post('/report/orderReportBySale', data).toPromise()
  }
  orderBySale(data: any) {
    return this._http.post('/report/orderBySale', data).toPromise()
  }
  topOrderProvince(data: any) {
    return this._http.post('/report/topOrderProvince', data).toPromise()
  }
  topOrderCountry(data: any) {
    return this._http.post('/report/topOrderCountry', data).toPromise()
  }
  topProductPending(data: any) {
    return this._http.post('/report/topProductPending', data).toPromise()
  }
  saleReport(data: any) {
    return this._http.post('/report/saleReport', data).toPromise()
  }
  saleReportProvince(data: any) {
    return this._http.post('/report/saleReportProvince', data).toPromise()
  }
  customerReportSummary(data: any) {
    return this._http.post('/report/customerReportSummary', data).toPromise()
  }
  customerByChannel(data: any) {
    return this._http.post('/report/customerByChannel', data).toPromise()
  }
  // newCustomerReport(data: any) {
  //   return this._http.post('/report/newCustomerReport', data).toPromise()
  // }
  customerCountry(data: any) {
    return this._http.post('/report/customerCountry', data).toPromise()
  }
  // newCustomerChannel(data: any) {
  //   return this._http.post('/report/newCustomerChannel', data).toPromise()
  // }
  // customerBuyAgainReport(data: any) {
  //   return this._http.post('/report/customerBuyAgainReport', data).toPromise()
  // }
  // customerBuyAgainChannel(data: any) {
  //   return this._http.post('/report/customerBuyAgainChannel', data).toPromise()
  // }
  // customerTopSpenderValue(data: any) {
  //   return this._http.post('/report/customerTopSpenderValue', data).toPromise()
  // }
  // customerTopSpenderQty(data: any) {
  //   return this._http.post('/report/customerTopSpenderQty', data).toPromise()
  // }
  // customerProvince(data: any) {
  //   return this._http.post('/report/customerProvince', data).toPromise()
  // }

  widgetAnalytic(data: any) {
    return this._http.post('/report/exportWidgetAnalytic', data).toPromise()
  }

  userorder(data: any) {
    return this._http.post('/services/ketshopweb-reports/warehouses-report/get_user_by_order_date', data).toPromise()
  }

  boxorder(data: any) {
    return this._http.post('/services/ketshopweb-reports/warehouses-report/get_box_by_order_date', data).toPromise()
  }

  averageReportOrder(data: any) {
    return this._http.post('/services/ketshopweb-reports/warehouses-report/get_average_report', data).toPromise()
  }

  averageShippinggroupReportOrder(data: any) {
    return this._http.post('/services/ketshopweb-reports/warehouses-report/get_time_stamp_shipping', data).toPromise()
  }
}
